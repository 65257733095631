<template>
  <div class="wrapper">
    <el-row :gutter="16">
      <el-col :span="6">
          <a href="#/data/1" target="_blank">客流大屏</a>
      </el-col>
      <el-col :span="6"></el-col>
      <el-col :span="6"></el-col>
      <el-col :span="6"></el-col>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>